import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';
import _ from 'lodash';

import cls from './Page.module.scss';

interface IPage {
  name?: string;
  title?: ReactNode;
  control?: ReactNode;
  children?: ReactNode;
}

export const Page = ({ name, title, control, children }: IPage) => {

  const navigate = useNavigate();

  return (
    <div className={cls.wrapper}>
      <Page.Content>
        <div className={cls.top}>
          <div className={cn([cls.name, !title && cls.title])}>
            {name}
          </div>
          <div className={cls.control}>
            {control}
            <Button icon={<CloseOutlined />} onClick={() => navigate(-1)} />
          </div>
        </div>
        <div className={cls.title}>
          {title}
        </div>
      </Page.Content>

      <Page.Divider big />

      {children}

    </div>
  );
};

interface IContent {
  children?: ReactNode;
}

Page.Content = function PageContent({ children }: IContent) {
  return (
    <div className={cls.content}>
      {children}
    </div>
  );
};

interface IDivider {
  big?: boolean;
  first?: boolean
}

Page.Divider = function PageDivider({ big, first }: IDivider) {
  return (
    <div className={cn([cls.divider, big && cls.big, first && cls.first])} />
  );
};

export interface IParam {
  key: string;
  label: string;
  component: ReactNode;
}

export interface IParams {
  data: IParam[];
}

Page.Params = function PageParams({ data }: IParams) {
  return (
    <div className={cls.params}>
      {_.map(data, param => (
        <React.Fragment key={param.key}>
          <div className={cls.label}>{param.label}</div>
          <div>{param.component}</div>
        </React.Fragment>
      ))}
    </div>
  );
};

// export default Page