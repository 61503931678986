import React, { useMemo } from 'react';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useTimersQuery } from 'generated/graphql';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';

import { FullSecondsFormat } from '../../shared/TimeFormat';
import { Tag } from '../Board/Tag';

import cls from './Timers.module.scss';

dayjs.extend(duration);

interface IReports {
    taskId: string;
}

export const Timers = observer(({ taskId }: IReports) => {
    // const store = useInstance(Store);

    // const {t} = useTranslation()

    const timers = useTimersQuery({
        variables: {
            filters: {
                task: { id: { eq: taskId } },
            },
            pagination: {
                limit: 1000,
            },
        },
        // pollInterval: 1000,
    });

    const totalSeconds: number = useMemo(
        () =>
            _.reduce(
                timers.data?.timers?.data,
                function (sum, item) {
                    if (item.attributes?.finishedAt !== null) {
                        return (
                            sum + dayjs(item.attributes?.finishedAt).diff(dayjs(item.attributes?.createdAt), 'seconds')
                        );
                    } else {
                        return sum;
                    }
                },
                0,
            ),
        [timers.data?.timers?.data],
    );

    return (
        <div className={cls.reports}>
            {timers.loading && <Skeleton.Input block active />}

            <div className={cls.grid}>
                {_.map(timers.data?.timers?.data, (timer, index) => {
                    const length: number = timers.data?.timers?.data.length!;

                    if (length > 4) {
                        if (index === 3) {
                            return (
                                <React.Fragment key={timer.id}>
                                    <div className={cls.user}></div>
                                    <div className={cls.time}></div>
                                    <div className={cls.comment}>...</div>
                                </React.Fragment>
                            )
                        }
                        if (index >= 2 && index <= length - 2 ) {
                            return null;
                        }

                    }

                    return (
                        <React.Fragment key={timer.id}>
                            <div className={cls.user}>
                                <Tag
                                    data={{
                                        id: timer.attributes?.user?.data?.id!,
                                        name: timer.attributes?.user?.data?.attributes?.username!,
                                        color: timer.attributes?.user?.data?.attributes?.color!,
                                    }}
                                />
                            </div>
                            <div className={cls.time}>
                                {timer.attributes?.finishedAt
                                    ? FullSecondsFormat(
                                          dayjs(timer.attributes.finishedAt).diff(
                                              dayjs(timer.attributes.createdAt),
                                              'seconds',
                                          ),
                                      )
                                    : '🕑'}
                            </div>
                            <div className={cls.comment}>
                                {dayjs(timer.attributes?.createdAt).format('DD.MM.YYYY HH:mm:ss')} —{' '}
                                {timer.attributes?.finishedAt &&
                                    dayjs(timer.attributes?.finishedAt).format('DD.MM.YYYY HH:mm:ss')}
                            </div>
                        </React.Fragment>
                    );
                })}

                <div className={cls.user}></div>
                <div className={cls.time}>
                    <b>{FullSecondsFormat(totalSeconds)}</b>
                </div>
                <div className={cls.comment}></div>
            </div>
        </div>
    );
});
