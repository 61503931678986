import React, { useEffect, useState } from 'react';

interface Props {
    sensitivity?: number
    children?: React.ReactNode;
    onBigger?: () => void;
    onSmaller?: () => void;
}

const PinchZoomComponent = ({sensitivity = 0.2, children, onBigger, onSmaller}: Props) => {
    const [distance, setDistance] = useState<number>(0);
    const [initialDistance, setInitialDistance] = useState<number>(0);
    const [action, setAction] = useState<'smaller'|'bigger'|undefined>(undefined);

    const handleTouchStart = (e:any) => {
        if (e.touches.length === 2) {
            const distance = getDistance(e.touches[0], e.touches[1]);
            setInitialDistance(distance);
            setDistance(distance);
        }
    };

    const handleTouchMove = (e:any) => {
        if (e.touches.length === 2 && initialDistance !== null) {
            const newDistance = getDistance(e.touches[0], e.touches[1]);
            setDistance(newDistance);
            // if (!action) {
                if (distance / initialDistance < 1 - sensitivity) {
                    setAction('smaller')
                }
                if (distance / initialDistance > 1 + sensitivity) {
                    setAction('bigger')
                }
            // }
        }
    };

    const handleTouchEnd = () => {
        setDistance(0);
        setInitialDistance(0);
        setAction(undefined)
    };

    const getDistance = (touch1:any, touch2:any) => {
        const dx = touch1.clientX - touch2.clientX;
        const dy = touch1.clientY - touch2.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };

    useEffect(() => {
        if (action === 'smaller' && onSmaller) {
            onSmaller()
        }
        if (action === 'bigger' && onBigger) {
            onBigger()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action]);

    return (
        <div
            style={{
                // position: 'absolute',
                width: '100%',
                // height: '100vh',
                // backgroundColor: 'gray',
                // transition: 'transform 0.2s',
                touchAction: 'pan-x pan-y',
                // touchAction: 'none', // Отключаем стандартные действия для касания
                // scale: (distance / initialDistance),
            }}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {children}
        </div>
    );
};

export default PinchZoomComponent;