// APP
export const ROOT_APP = '';
export const INDEX = '/';
export const FEEDBACK = '/feedback';
export const ARCHIVE = '/archive';
export const TASK = '/task/:id';
export function goToTask (id: string) { return `/task/${id}`}
export function goToNewTask (status: string, client?: string, epic?: string) { return `/task/new/?status=${status}&client=${client}&epic=${epic}`}



// USER
export const ROOT_USER = '/user';
export const REGISTER = '/register';
export const LOGIN = '/login';
export const ABOUT = '/about';
export const LOGOUT = '/user/logout';
export const FORGET_PASSWORD = '/forget_password';
export const RESET_PASSWORD = '/reset_password';



// DOMAIN
export const SETTINGS = '/settings';
export const DOMAIN_CREATE = '/domain/create';
export const DOMAIN_ACTIVATION = '/domain/activation';
export const DOMAIN_INVITE = '/domain/invite';
export const DOMAIN_RENAME = '/domain/rename';
export const DOMAIN_EPICS = '/domain/epics';
export const DOMAIN_CLIENTS = '/domain/clients';

// INVITE
export const INVITE = '/invite/:UID';


// 404
export const NOT_FOUND = '/404';

