import React, { useState } from 'react';
import _ from 'lodash';

import PinchZoomComponent from '../../pages/PinchZoomComponent';
import { IStatus } from '../../shared/status';

import { Column } from './Column';

import cls from './Board.module.scss';

export interface ITag {
    id: string;
    name: string;
    color: string;
}

export interface BoardData {
    id: string;
    name: string;
    status: string;
    client?: ITag;
    epic?: ITag;
    users: ITag[];
    timersSeconds: number;
    reportsMinutes: number;
}

interface IBoard {
    status: IStatus[];
    data: BoardData[];
    onDrag?: (id: string, status: string) => void;
    onOpen?: (id: string) => void;
    onCreate?: (status: string) => void;
    loading?: boolean;
}

export const Board = ({ loading, status, data, onCreate, onOpen, onDrag }: IBoard) => {
    const [dragId, setDragId] = useState<string | undefined>(undefined);
    const [small, setSmall] = useState<boolean>(false);

    const onCardOpen = (id: string) => {
        if (onOpen) {
            onOpen(id);
        }
    };

    const onColumnDrop = (status: string) => {
        if (onDrag) {
            onDrag(dragId!, status);
        }
    };

    return (
        <PinchZoomComponent
            onBigger={() => {
                if (small) (setSmall(false))
                // message.info('Bigger');
            }}
            onSmaller={() => {
                if (!small) (setSmall(true))
                // message.info('Smaller');
            }}
        >
            <div className={cls.board}>
                {_.map(status, (status, index) => {
                    const columnData = _.filter(data, (task) => task.status === status.name);

                    return (
                        <Column
                            small={small}
                            key={index}
                            loading={loading}
                            status={status}
                            data={columnData}
                            onCreate={() => onCreate && onCreate(status.name)}
                            onCardOpen={onCardOpen}
                            onCardDrag={setDragId}
                            onColumnDrop={onColumnDrop}
                        />
                    );
                })}
            </div>
        </PinchZoomComponent>
    );
};
