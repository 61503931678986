import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Badge, Button, Dropdown } from 'antd';
import _ from 'lodash';

interface Item {
    id: string;
    name: string;
}

interface IFilter {
    title: string;
    data: Item[];
    icon?: React.ReactNode;
    selected: string[];
    onChance: (data: string[] | null) => void;
}

export const Filter = ({ title, icon, data, selected, onChance }: IFilter) => {
    const { t } = useTranslation();

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }

    const [width, setWidth] = useState(getWindowDimensions().width);
    useEffect(() => {
        function handleResize() {
            setWidth(getWindowDimensions().width);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onClick = (id: string) => {
        let newSelected = [...selected];
        if (selected.includes(id)) {
            const index = newSelected.indexOf(id);
            if (index !== -1) {
                newSelected.splice(index, 1);
            }
        } else {
            newSelected.push(id);
        }
        onChance(newSelected);
    };

    const menu: MenuProps['items'] = _.map(data, (item) => ({
        key: item.id,
        label: item.name,
        onClick: () => onClick(item.id),
        icon: selected.includes(item.id) && <CheckOutlined />,
    }));

    menu.unshift({
        key: 'all',
        label: t('All'),
        onClick: () => onChance(null),
    });

    return (
        <Badge count={selected.length}>
            <Dropdown
                menu={{ selectedKeys: selected.length > 0 ? selected : ['all'], items: menu }}
                trigger={['click']}
            >
                {width > 768 ? (
                    <Button shape={'round'} icon={icon}>
                        {title}
                    </Button>
                ) : (
                    <Button shape={'circle'} icon={icon}></Button>
                )}
            </Dropdown>
        </Badge>
    );
};
