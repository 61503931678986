import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, type MenuProps } from 'antd';
import cn from 'classnames';

import cd2c from '../../layouts/cd2c.svg';
import saturn from '../../layouts/saturn.svg';
import { INDEX, LOGIN, REGISTER } from '../../routes';

import background from './background.jpg';
import dzen from './dzen.png';
import team from './team.svg';

import cls from './Landing.module.scss';

export const Landing = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [workspace, setWorkspace] = React.useState<string>('Work');
    const menuDomains: MenuProps['items'] = [
        {
        key: 'Work',
        label: t('Work'),
        onClick: () => {
            setWorkspace('Work')
            },
        },
        {
        key: 'Family',
        label: t('Family'),
        onClick: () => {
            setWorkspace('Family')
            },
        },
    ]

    return (
        <div>
            <div className={cls.cover} style={{ backgroundImage: `url(${background})` }}>
                <div className={cls.wrapper}>
                    <div className={cls.control}>
                        <Button size={'large'} onClick={() => navigate(LOGIN)}>
                            {t('Entrance')}
                        </Button>
                        <Button size={'large'} type={'primary'} onClick={() => navigate(REGISTER)}>
                            {t('Registration')}
                        </Button>
                    </div>

                    <div className={cls.content}>
                        <div className={cls.app}>
                            <Link to={INDEX}>
                                <div className={cls.logo}>
                                    <img src={saturn} alt={'saturn'} />
                                </div>
                            </Link>
                            <div className={cls.name}>{'Saturn'}</div>
                        </div>

                        <div className={cls.subheadline}>{t('Put tasks on the board, increase your productivity')}</div>

                        <h3>{t('Focus on the really important things')}</h3>
                    </div>

                    <div className={cls.cd2c}>
                        <img src={cd2c} alt={'CD2C'} />
                    </div>
                </div>
            </div>

            <div className={cls.blocks}>
                {/*<div className={cls.block}>*/}
                {/*    <h3>{t('Experience')}</h3>*/}
                {/*    <div className={cls.subheadline}>{t('Learn how to manage tasks effectively')}</div>*/}
                {/*</div>*/}
                <div className={cls.block}>
                    <h3>{t('Team')}</h3>
                    <div className={cls.subheadline}>{t('Share tasks with colleagues or family')}</div>
                    <div>
                        <img src={team} alt={'Team'} />
                    </div>
                </div>
                <div className={cls.block}>
                    <h3>{t('Workspaces')}</h3>
                    <div className={cls.subheadline}>
                        {t('Create and work in different workspaces for different teams')}
                    </div>
                    <div>
                        <Dropdown menu={{ selectedKeys: [workspace], items: menuDomains }} trigger={['click']}>
                            <Button shape={'round'} className={cls.button}>
                                {t(workspace)} <DownOutlined className={cls.icon} />
                            </Button>
                        </Dropdown>
                    </div>
                </div>
                <div className={cls.block}>
                    <h3>{t('Fares')}</h3>
                    <div className={cls.fares}>
                        <div className={cls.fare}>
                            <span>{t('1 Workspace, 2 Users')}</span>
                            <span className={cls.duration}>{t('1 month')}</span>
                            <span className={cls.amount}>{t('Free')}</span>
                        </div>
                        <div className={cn([cls.fare, cls.disabled])}>
                            <span>{t('1 Workspace, 2 Users')}</span>
                            <span className={cls.duration}>{t('1 month')}</span>
                            <span className={cls.amount}>{t('439 ₽')}</span>
                        </div>
                        <div className={cn([cls.fare, cls.disabled])}>
                            <span>{t('1 Workspace, 2 Users')}</span>
                            <span className={cls.duration}>{t('6 months')}</span>
                            <span className={cls.old}>{t('2 634 ₽')}</span>
                            <span className={cls.amount}>{t('2 199 ₽')}</span>
                        </div>
                        <div className={cn([cls.fare, cls.disabled])}>
                            <span>{t('1 Workspace, 2 Users')}</span>
                            <span className={cls.duration}>{t('1 year')}</span>
                            <span className={cls.old}>{t('5 268 ₽')}</span>
                            <span className={cls.amount}>{t('4 390 ₽')}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={cls.control}>
                        <Button size={'large'} onClick={() => navigate(LOGIN)}>
                            {t('Entrance')}
                        </Button>
                        <Button size={'large'} type={'primary'} onClick={() => navigate(REGISTER)}>
                            {t('Registration')}
                        </Button>
                    </div>
                </div>

                <div className={cls.footer}>
                    <span className={cls.dzen}>
                        <a href={'https://dzen.ru/cd2c'} target={'_blank'} rel={'noreferrer'}>
                            <img src={dzen} alt={'dzen'}/> {t('We are on Dzen')}
                        </a>
                    </span>
                    {/*<span>*/}
                    {/*    <Link to={'Пользовательское соглашение'}>{t('We are on Dzen')}</Link>*/}
                    {/*</span>*/}
                    {/*<span>*/}
                    {/*    <Link to={'Политика обработки данных'}>{t('We are on Dzen')}</Link>*/}
                    {/*</span>*/}
                    {/*<span>*/}
                    {/*    <Link to={FEEDBACK}>{t('Feedback')}</Link>*/}
                    {/*</span>*/}
                    <span>
                        {t('Made in')} <img src={cd2c} alt={'CD2C'} />
                    </span>
                </div>
            </div>
        </div>
    );
};
