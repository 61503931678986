import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CustomIcon = () => (
    <svg width="1em" height="1em" viewBox="0 0 36 36" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.39771 18C5.39771 11.1991 11.0018 5.63483 17.8512 5.63483C24.7007 5.63483 30.3047 11.1991 30.3047 18C30.3047 24.8008 24.7007 30.3651 17.8512 30.3651C11.0018 30.3651 5.39771 24.8008 5.39771 18ZM25.7591 10.1481C23.642 8.04603 20.84 6.87134 17.8511 6.87134C14.8 6.87134 12.0603 8.04603 9.94316 10.1481C7.82606 12.2502 6.64298 15.0323 6.64298 18C6.64298 20.9676 7.82606 23.7498 9.94316 25.8519C12.0603 27.9539 14.8623 29.1286 17.8511 29.1286C20.84 29.1286 23.642 27.9539 25.7591 25.8519C27.8762 23.7498 29.0593 20.9676 29.0593 18C29.0593 15.0323 27.8762 12.2502 25.7591 10.1481Z"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.9771 18.7786C30.5744 25.5958 24.9183 31 18 31C13.8303 31 10.1191 29.0369 7.74021 25.9845C3.71076 25.9986 0.87344 25.062 0.293368 23.2101C-0.301574 21.3108 1.60286 18.8475 5.08476 16.5074C5.82512 10.0307 11.3251 5 18 5C21.8584 5 25.3243 6.68096 27.7051 9.3505C32.028 9.24555 35.1009 10.1813 35.7073 12.1171C36.2987 14.0053 34.4201 16.4508 30.9771 18.7786ZM27.616 20.754C26.42 24.9376 22.5676 28 18 28C15.5839 28 13.3679 27.1431 11.6393 25.7167C14.2011 25.3942 17.0286 24.7794 19.9433 23.8664C22.7722 22.9803 25.3735 21.9103 27.616 20.754ZM27.9632 17.1364C25.5916 18.5428 22.5359 19.9106 19.0465 21.0036C15.4638 22.1258 12.0912 22.7494 9.29737 22.9294C8.47156 21.4746 8 19.7924 8 18C8 12.4772 12.4772 8 18 8C23.2319 8 27.5254 12.0179 27.9632 17.1364ZM30.7086 15.2501C31.628 14.5005 32.2247 13.8442 32.5553 13.3346C32.6285 13.2218 32.6814 13.1285 32.7196 13.053C32.6452 13.0128 32.5484 12.9663 32.424 12.9154C31.8485 12.6801 30.9563 12.4775 29.7303 12.3895C30.162 13.2904 30.493 14.2488 30.7086 15.2501ZM5.96017 22.9124C5.60436 22.0412 5.34035 21.1228 5.18017 20.1692C4.32397 20.8805 3.76232 21.504 3.44529 21.9927C3.37216 22.1054 3.31922 22.1988 3.28099 22.2743C3.35546 22.3145 3.45223 22.361 3.57659 22.4118C4.10243 22.6268 4.89258 22.8144 5.96017 22.9124Z"
        />
    </svg>
);

const SaturnOutlined = (props: Partial<CustomIconComponentProps>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon component={CustomIcon} {...props} />
);

export default SaturnOutlined;
