import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { provider, useInstance } from 'react-ioc';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import {YMInitializer} from 'react-yandex-metrika';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { ConfigProvider, Skeleton, theme } from 'antd';
import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import ru from 'dayjs/locale/ru';
import { observer } from 'mobx-react-lite';

import { Metrika } from './components/common/Metrika';
import ScrollToTop from './components/common/ScrollToTop';
import GetMe from './features/GetMe/GetMe';
import { Layout } from './layouts/Layout';
import ApolloClientDI from './model/graphql/ApolloClientDI';
import { Store } from './model/store/Store';
import StoreDI from './model/store/StoreDI';
import { LoginPage } from './pages/Auth/LoginPage';
import LogoutPage from './pages/Auth/LogoutPage';
import RegisterPage from './pages/Auth/RegisterPage';
import { Clients } from './pages/Domain/Clients/Clients';
import CreateDomainPage from './pages/Domain/CreateDomainPage';
import { Domain } from './pages/Domain/Domain';
import { DomainActivation } from './pages/Domain/DomainActivation';
import { Epics } from './pages/Domain/Epics/Epics';
import { NewInvite } from './pages/Domain/Invite/NewInvite';
import { Rename } from './pages/Domain/Rename/Rename';
import { Archive } from './pages/Index/Archive';
import { Index } from './pages/Index/Index';
import { Invite } from './pages/Invite/Invite';
import { Landing } from './pages/Landing/Landing';
import { Task } from './pages/Task/Task';
import {
  ARCHIVE, DOMAIN_ACTIVATION,
  DOMAIN_CLIENTS,
  DOMAIN_CREATE,
  DOMAIN_EPICS,
  DOMAIN_INVITE, DOMAIN_RENAME,
  INDEX, INVITE,
  LOGIN,
  LOGOUT,
  REGISTER, ROOT_APP, SETTINGS, TASK
} from './routes';

import './App.scss';

const App: FC = () => {
  const store = useInstance(Store);
  const { hydrated, auth } = store;
  const { hasAuthToken } = auth;
  const apolloClient = useInstance(ApolloClient);
  const { i18n } = useTranslation();

  dayjs.locale(i18n.language === 'ru' ? ru : en);

  if (!hydrated) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <ApolloProvider client={apolloClient}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#7037CD',
            fontFamily: 'SF Pro, sans-serif',
            fontSize: 15
            // controlHeight: 40,
          },
          algorithm: theme.darkAlgorithm
        }}>
        <Router>
          <ScrollToTop />
          <YMInitializer accounts={[56752696]} />
          <Metrika/>


          {!hasAuthToken && (
            <Routes>
              <Route path={INDEX} element={<Landing />} />
              <Route path={REGISTER} element={<RegisterPage />} />
              <Route path={LOGIN} element={<LoginPage />} />
              <Route path={INVITE} element={<Invite />} />
              <Route path="*" element={<Navigate to={LOGIN} />} />
            </Routes>
          )}

          {hasAuthToken &&
            <>
              <GetMe />

              {store.domain.loaded === false &&
                <Routes>
                  <Route path="*" element={<Skeleton active={true} />} />
                </Routes>
              }
              {store.domain.loaded === true && store.domain.selectedDomain === null &&
                <Routes>
                  <Route path={DOMAIN_CREATE} element={<CreateDomainPage />} />
                  <Route path={INVITE} element={<Invite />} />
                  <Route path={LOGOUT} element={<LogoutPage />} />
                  <Route path="*" element={<Navigate to={DOMAIN_CREATE} />} />
                </Routes>
              }
              {store.domain.loaded === true && store.domain.selectedDomain !== null &&
                <>
                  {store.domain.currentDomain().blocked &&
                    <Routes>

                      <Route path={DOMAIN_CREATE} element={<CreateDomainPage />} />
                      <Route path={DOMAIN_ACTIVATION} element={<DomainActivation />} />
                      <Route path={INVITE} element={<Invite />} />
                      <Route path={LOGOUT} element={<LogoutPage />} />
                      <Route path="*" element={<DomainActivation />} />

                    </Routes>
                  }
                  {!store.domain.currentDomain().blocked &&
                    <Routes>

                      <Route path={INDEX} element={<Index />} />
                      <Route path={ARCHIVE} element={<Archive />} />

                      <Route path={ROOT_APP} element={<Layout />}>
                        <Route path={TASK} element={<Task />} />
                      </Route>

                      {store.domain.isAdmin() &&
                        <Route path={ROOT_APP} element={<Layout />}>
                          <Route path={SETTINGS} element={<Domain />} />
                          <Route path={DOMAIN_INVITE} element={<NewInvite />} />
                          <Route path={DOMAIN_RENAME} element={<Rename />} />
                          <Route path={DOMAIN_EPICS} element={<Epics />} />
                          <Route path={DOMAIN_CLIENTS} element={<Clients />} />
                        </Route>
                      }

                      <Route path={LOGOUT} element={<LogoutPage />} />

                      <Route path={DOMAIN_CREATE} element={<CreateDomainPage />} />
                      <Route path={DOMAIN_ACTIVATION} element={<DomainActivation />} />
                      <Route path={INVITE} element={<Invite />} />

                      <Route path="*" element={<Navigate to={INDEX} />} />

                    </Routes>
                  }
                </>
              }

            </>
          }

        </Router>
      </ConfigProvider>
    </ApolloProvider>
  );
};

export default provider(
  StoreDI(),
  ApolloClientDI()
)(observer(App));
