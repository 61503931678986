import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstance } from 'react-ioc';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { OutputData } from '@editorjs/editorjs';
import { Button, Divider, Dropdown, Form } from 'antd';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { goToTask } from 'routes';

import Editor from '../../components/Editor/Editor';
import { SelectClient } from '../../components/Form/SelectClient/SelectClient';
import { SelectEpic } from '../../components/Form/SelectEpic/SelectEpic';
import { SelectStatus } from '../../components/Form/SelectStatus/SelectStatus';
import { SelectTeam } from '../../components/Form/SelectTeam/SelectTeam';
import { TextArea } from '../../components/Form/TextArea/TextArea';
import { IParam, Page } from '../../components/Page/Page';
import { Reports } from '../../components/Report/Reports';
import { Timers } from '../../components/Timer/TImers';
import { useCreateTaskMutation, useTaskQuery, useUpdateTaskMutation } from '../../generated/graphql';
import { Store } from '../../model/store/Store';

import cls from './Task.module.scss';

export interface IFormTask {
    name?: string,
    status: string,
    client?: string,
    epic?: string,
    users: string[],
    body?: string,
    content: OutputData,
}


export const Task = observer(() => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const store = useInstance(Store);

    const [ready, setReady] = useState(id === 'new');
    const [taskData, setTaskData] = useState<IFormTask>({
        name: undefined,
        client: location.state?.client || undefined,
        epic: location.state?.epic || undefined,
        status: location.state?.status || 'New',
        content: {
            time: Date.now(),
            blocks: [],
        },
        users: [store.auth.id!],
    });
    const [editorData, setEditorData] = useState<OutputData>(taskData.content)

    const query = useTaskQuery({
        variables: {
            id: id,
        },
        skip: id === 'new',
        onCompleted: (data) => {
            setTaskData({
                name: data?.task?.data?.attributes?.name,
                client: data?.task?.data?.attributes?.company?.data?.id!,
                epic: data?.task?.data?.attributes?.epic?.data?.id!,
                status: data?.task?.data?.attributes?.status!,
                content: data?.task?.data?.attributes?.content!,
                users: _.map(data?.task?.data?.attributes?.users?.data, (item) => item.id!),
            })
            setReady(true)
        }
    });

    // const ready:boolean = useMemo(() => id === 'new' || !!query.data?.task?.data, [id, query.data]);

    const [createTask] = useCreateTaskMutation();
    const [updateTask, updateTaskData] = useUpdateTaskMutation();

    const goBack = () => {
        navigate(-1);
    };

    const onMutateTask = async () => {
        if (id !== 'new') {
            console.log('updateTask');
            await updateTask({
                variables: {
                    id: id!,
                    data: {
                        name: taskData.name,
                        status: taskData.status,
                        company: taskData.client,
                        epic: taskData.epic,
                        users: taskData.users,
                        content: editorData,
                    },
                },
                onCompleted: () => {
                    query.refetch();
                },
            });
        } else {
            if (taskData.name) {
                await createTask({
                    variables: {
                        data: {
                            name: taskData.name,
                            content: editorData,
                            status: taskData.status,
                            company: taskData.client,
                            epic: taskData.epic,
                            users: taskData.users,
                            domain: store.domain.selectedDomain,
                        },
                    },
                    onCompleted: (data) => {
                        navigate(goToTask(data.createTask?.data?.id!), { replace: true });
                    },
                });
            }
        }
    };

    // const debounceOnMutateTask =

    useEffect(() => {
        if (ready) {
            onMutateTask().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskData])
    useEffect(() => {
        if (ready) {
            onMutateTask().then()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorData])

    const onDeleteTask = async () => {
        await updateTask({
            variables: {
                id: id!,
                data: {
                    enabled: false,
                },
            },
            onCompleted: () => {
                goBack();
            },
        });
    };

    const menu = {
        items: [
            {
                key: 'delete',
                label: t('Delete'),
                danger: true,
                icon: <DeleteOutlined />,
                onClick: id !== 'new' ? onDeleteTask : goBack,
            },
        ],
    };

    const params: IParam[] = [
        {
            key: 'status',
            label: t('Status'),
            component: <SelectStatus value={taskData.status} bordered={false} variants={'borderless'} className={cls.select}
                                     onChange={(value:string) => setTaskData({...taskData, status: value})}
            />,
        },
        {
            key: 'client',
            label: t('Client'),
            component: <SelectClient value={taskData.client} bordered={false} variants={'borderless'} className={cls.select}
                                     onChange={(value:string) => setTaskData({...taskData, client: value})}
                                     onCreate={(value:string) => setTaskData({...taskData, client: value})}
            />,
        },
        {
            key: 'epic',
            label: t('Epic'),
            component: <SelectEpic value={taskData.epic} bordered={false} variants={'borderless'} className={cls.select}
                                   onChange={(value:string) => setTaskData({...taskData, epic: value})}
                                   onCreate={(value:string) => setTaskData({...taskData, epic: value})}
            />,
        },
        {
            key: 'team',
            label: t('Team'),
            component: <SelectTeam value={taskData.users} bordered={false} variants={'borderless'} className={cls.select}
                                   onChange={(value:string[]) => setTaskData({...taskData, users: value})}
            />,
        },
    ];

    // console.log('ready', ready);

    return (
        <Page
            title={
                <TextArea value={taskData.name} onUpdate={(value:string) => setTaskData({...taskData, name: value})} />
            }
            control={
                <Dropdown menu={menu} trigger={['click']}>
                    <Button icon={<MoreOutlined />} loading={query.loading || updateTaskData.loading} />
                </Dropdown>
            }
        >
            <Page.Content>
                <Page.Params data={params} />
            </Page.Content>

            <Page.Divider first />

            {id !== 'new' &&
                <Page.Content>
                    <Form.Item label={t('In focus')}>
                        <Timers taskId={id!}/>
                    </Form.Item>
                    <Divider style={{ margin: '8px 0' }} />
                </Page.Content>
            }

            {id !== 'new' &&
                <Page.Content>
                    <Form.Item label={t('Reports')}>
                        <Reports taskId={id!}/>
                    </Form.Item>
                    <Divider style={{ margin: '8px 0' }} />
                </Page.Content>
            }

            {/*<Page.Content>*/}
            <div className={cls.editor}>
                {ready &&
                    <>
                    <Editor data={taskData.content} onUpdate={(value) => {setEditorData(value)}} />
                    </>
                }
            </div>
            {/*</Page.Content>*/}

        </Page>
    );
});
